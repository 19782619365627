.header {
	display: flex;
	align-items: center;
	justify-content:flex-start;
}

.menu a {
	display: inline-block;
	padding: .5em 1.5em;
    color: black;
}

.active {
	text-decoration: none;
}

@media screen and (min-width : 1200px)
{
	.logo {
		display:inline-block;
		margin-right: 2em;
	}
	
	.logo img {
		width: 218px;
		height: 50px;
	}
	
	.menu ul {
		margin: 1em 0 .5em;
		text-align: center;
	}
	
	.menu li {
		display: inline;
		font-size: 100%;
		margin-right: 2em;
	}
}

@media screen and (min-width: 768px) and (max-width: 1200px) 
{
	.logo {
		display:inline-block;
		margin-right: 0.5em;
	}
	
	.logo img {
		width: 109px;
		height: 25px;
	}
	
	.menu ul {
		margin: 1em 0 .5em;
		text-align: center;
	}
	
	.menu li {
		display: inline;
		font-size: 80%;
		margin-right: 0.5em;
	}
}

@media screen and (max-width : 767px)
{
	.logo {
		display:inline-block;
		margin-right: 0.5em;
	}
	
	.logo img {
		width: 65px;
		height: 15px;
	}
	
	.menu ul {
		margin: 1em 0 .5em;
		text-align: center;
	}
	
	.menu li {
		display: inline;
		font-size: 30%;
	}	
}